import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { TokenCasService } from '../../providers/token-cas/token-cas.service';
import { AuthService } from '../../providers/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertCloseSessionComponent } from '../../common/popus/alert-close-session/alert-close-session.component';

export const authGuard: CanActivateFn = async () => {
  const auth: AuthService = inject(AuthService);
  const cas: TokenCasService = inject(TokenCasService);
  const ngbModal: NgbModal = inject(NgbModal);

  await auth.initRedirect();
  if (auth.isLoggedIn()) {
  
    if (auth.roles && auth.roles.length > 0 ) {
      if (cas.validateCAS()) {
        return true;
      } else {
        window.location.href = cas.getCodeOC();
        return false;
      }
    } else {
      ngbModal.open(AlertCloseSessionComponent, { backdropClass: 'cbaf-modal-backdrop' });
      return false;
    }
  } else {
    auth.startAuthentication();
    return false;
  }
}