import { environment } from "../../../environments/environment";
export function getENV(): string {
    let env = environment.redirectUri;
    if (env.search(ENV.PROD_CODE) > 0) return ENV.PROD;
    else if (env.search(ENV.DEV_CODE) > 0) return ENV.DEV;
    else if (env.search(ENV.CERT_CODE) > 0) return ENV.CERT;
    else return ENV.LOCAL;
}
export enum ENV {
    CERT = 'cert',
    PROD = 'prod',
    DEV = 'dev',
    LOCAL = 'local',
    CERT_CODE = 'cbafcert.bcpperu',
    DEV_CODE = 'cbafdesa.bcpperu',
    PROD_CODE = 'p01'

}