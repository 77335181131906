import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaderService } from '../../shared/services/http-header.service';
import { ENDPOINTS } from '../services/endpoint.enum';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  public endPointsReport = [
    `${ENDPOINTS.DASHBOARD_CATEGORIES}`,
    `${ENDPOINTS.DASHBOARD_MOTOR}`,
    `${ENDPOINTS.DASHBOARD_PENDING}`,
    `${ENDPOINTS.REPORT_ALERT}`,
    `${ENDPOINTS.REPORT_ALERT_TRAKING}`,
    `${ENDPOINTS.REPORT_DOWNLOAD_EXCEL}`,
    ENDPOINTS.FRAUDALERT_V1_ACTIONS,
    ENDPOINTS.FRAUDALERT_V1_ALERTS,
    `${ENDPOINTS.FRAUDALERT_V1_ALERTS}?stateAlert=pendiente`,
    ENDPOINTS.FRAUDALERT_V1_NOTIFICATIONS,
    ENDPOINTS.FRAUDALERT_V1_PREVIEW,
    ENDPOINTS.FRAUDALERT_V1_PREVIEW_UNBLOCKED,
    ENDPOINTS.FRAUDALERT_V1_TRACKING,
  ];
  constructor(
    private httpHeaders: HttpHeaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes('oauth2/token') && !req.url.includes('authserver')) {
     const headers = this.httpHeaders.getInterceptorHeadersWithOutToken();
     req = req.clone({ setHeaders: headers });
    }
    return next.handle(req);
  }

}
