<div
  class="c-dropdown dropdown default"
  [ngClass]="{ show: isOpen }"
  tabindex="1"
  (blur)="onBlurDropdown()"
>
  <div 
    class="c-dropdown__toggle dropdown-toggle"
    (click)="onClickDropdown()"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    role="listbox"
  >
    <div class="c-dropdown__label title-dropdown">{{ objDropdown.label }}</div>
    <div
      class="c-dropdown__placeholder text c-dropdown__placeholder-col"
      [ngClass]="{ isSelected: objDropdown?.itemSelected }"
    >
      {{ !objDropdown.itemSelected ? objDropdown.placeholder : "" }}
      <div
        class="c-dropdown__placeholder-col-left"
        *ngIf="objDropdown.itemSelected"
        [ngClass]="{
          'full-width': !objDropdown.itemSelected.textRight,
          'half-width': objDropdown.itemSelected.textRight
        }"
      >
        {{
          objDropdown.itemSelected.textLeft
            ? objDropdown.itemSelected.textLeft
            : ""
        }}
      </div>
      <div
        class="c-dropdown__placeholder-col-right"
        *ngIf="objDropdown.itemSelected"
        [ngClass]="{
          'full-width': !objDropdown.itemSelected.textRight,
          'half-width': objDropdown.itemSelected.textRight
        }"
      >
        {{
          objDropdown.itemSelected.textRight
            ? objDropdown.itemSelected.textRight
            : ""
        }}
      </div>
    </div>
  </div>
  <div class="c-dropdown__menu dropdown-menu" [ngClass]="{ show: isOpen }">
    <div
      class="c-dropdown__menu-col dropdown-item"
      *ngFor="let item of objDropdown.items"
      [ngClass]="{
        selected: objDropdown.itemSelected
          ? item.id === objDropdown.itemSelected.id
          : objDropdown.itemSelected
      }"
      (click)="onItem(item)"
    >
      <div
        class="c-dropdown__menu-col-left"
        *ngIf="item.textLeft"
        [ngClass]="{
          'full-width': !item.textRight,
          'half-width': item.textRight
        }"
      >
        {{ item.textLeft }}
      </div>
      <div
        class="c-dropdown__menu-col-right"
        *ngIf="item.textRight"
        [ngClass]="{
          'full-width': !item.textRight,
          'half-width': item.textRight
        }"
      >
        {{ item.textRight }}
      </div>
    </div>
  </div>
</div>
