import { Component, OnInit, inject } from '@angular/core';
import { StoreService } from '../providers/store/store.service';
import { TokenCasService } from '../providers/token-cas/token-cas.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AlertCloseSessionComponent } from '../common/popus/alert-close-session/alert-close-session.component';
import { AuthService } from '../providers/auth';
import { LOGIN_REPOSITORY } from '../shared/repositories/login/repository/login-http-repository.module';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'visor-validate-cas',
  templateUrl: './validate-cas.component.html',
  styleUrls: ['./validate-cas.component.scss']
})
export class ValidateCasComponent implements OnInit {
  private readonly loginRepository = inject(LOGIN_REPOSITORY);

  showLoading: boolean = false;
  public roles = [];

  constructor(
    private router: Router,
    private token: TokenCasService,
    private store: StoreService,
    private auth: AuthService,
    private _modal: NgbModal,
    private _modalConfig: NgbModalConfig,
  ) {
    this.showLoading = true;
    this._modalConfig.backdrop = 'static';
    this._modalConfig.keyboard = false;
  }

  ngOnInit(): void {
    const codeOC = this.getExchangeCode();
    if (!codeOC) {
      window.location.href = this.token.getCodeOC();
    } else {
      //Using Promises for validation order
      lastValueFrom(this.token.getAccessToken(codeOC))
        .then((res) => {
          this.store.saveTokenCas(res.access_token);
          this.store.saveRefreshCAS(res.refresh_token);
          this.store.saveExpiresInCAS(res.expires_in);
          this.store.saveStartedCAS();
          return this.auth.getTokenAzure();
        })
        .then((res)=>{
          this.store.saveTokenAzure(res);
          return lastValueFrom(this.loginRepository.userLogin());
        })
        .then(response => {
          console.log(response);
          this.showLoading = false;
          this.router.navigate(['']);
        })
        .catch(err => {
          console.log(`Error throwed from validate CAS ${err.status}`);
          this.showLoading = false;
          this._modal.open(AlertCloseSessionComponent, { backdropClass: 'cbaf-modal-backdrop' });
        })
    }
  }

  getExchangeCode(): string {
    const url = window.location.href;
    const startIndex = url.indexOf('code=') + 5;
    const endIndex = url.indexOf('&nonce=');
    return (startIndex < 0 || endIndex < 0) ? '' : url.substring(startIndex, endIndex);
  }
}