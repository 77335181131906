import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AlertCloseSessionComponent } from '../../common/popus/alert-close-session/alert-close-session.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private modal: NgbModal,
    private _modalConfig: NgbModalConfig,
    private spinner: NgxSpinnerService
  ) {
    this._modalConfig.backdrop = 'static';
    this._modalConfig.keyboard = false;
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
    
          if (error.status == 500 && error.error?.code === 'FX0080') {
            this.spinner.hide()
            this.modal.open(AlertCloseSessionComponent, { backdropClass: 'cbaf-modal-backdrop' });
            return new Observable<HttpEvent<any>>();
          }
          return throwError(() => error);

        })
      );
  }
}