import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { EmptyMsgComponent } from "./empty-msg.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [EmptyMsgComponent],
    imports: [
        CommonModule,
        FormsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [EmptyMsgComponent]
})
export class EmptyMsgModule { }