<div class="custom-switch" (click)="toggleSwitch()">
  <input type="checkbox" #checkbox>
  <div class="switch-wrapper">
    <div class="switch-content">
      <span class="content-slider"></span>
    </div>
  </div>
  <div class="switch-label">
    <span class="label-description">{{label}}</span>
  </div>
</div>
