import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionsService } from '../../group-alert/services/actions.service';

@Component({
  selector: 'visor-actualizar-mensaje',
  templateUrl: './actualizar-mensaje.component.html',
  styleUrls: ['./actualizar-mensaje.component.scss']
})
export class ActualizarMensajeComponent implements OnInit {
  public acciones = [
    {
      value: 'descarte',
      label: 'Descarte'
    },
    {
      value: 'buena',
      label: 'Buena'
    },
    {
      value: 'pendiente',
      label: 'Pendiente'
    },
    {
      value: 'fraude',
      label: 'Fraude'
    }
  ];
  public accionesTarjeta = [];
  public accionesFraude = [];
  public tipoMensaje: [
    {
      value: 1,
      label: 'Tipo 1',

    }
  ]
  constructor(
    private modalActive: NgbActiveModal,
    private actionsService: ActionsService
  ) { }

  ngOnInit() {
    this.accionesTarjeta = this.actionsService.getCardActions();
    this.accionesFraude = this.actionsService.getFraudActions();
  }
  close() {
    this.modalActive.close();
  }
}
