import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActualizarMensajeComponent } from '../actualizar-mensaje/actualizar-mensaje.component';

@Component({
  selector: 'visor-parametrizacion',
  templateUrl: './parametrizacion.component.html',
  styleUrls: ['./parametrizacion.component.scss']
})
export class ParametrizacionComponent implements OnInit {
  active = 'tab-a';
  constructor(
    private modal:NgbModal,
    private modalConfig: NgbModalConfig

  ) { }

  ngOnInit() {
  }

}
