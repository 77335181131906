import { Component, OnInit, Input, Output, EventEmitter, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AlertOneButtonComponent } from '../../../../common/popus/alert-one-button/alert-one-button.component';
import { BACKLOG_PARAMETRIZACION_REPOSITORY } from '../../repository/backlog-parametrizacion-repository.module';

@Component({
  selector: 'visor-agregar-segmento',
  templateUrl: './agregar-segmento.component.html',
  styleUrls: ['./agregar-segmento.component.scss']
})
export class AgregarSegmentoComponent implements OnInit {
  private readonly backlogParametrizacionRepository = inject(BACKLOG_PARAMETRIZACION_REPOSITORY);

  @Input() segments;
  @Output() upDateData = new EventEmitter();

  public formSegment: UntypedFormGroup;

  get getFormControllerName() {
    return this.formSegment.get('formSegmentName');
  }

  get getFormControllerIdSegment() {
    return this.formSegment.get('formIdSegmento');
  }
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private activeModal: NgbActiveModal,
    private _route: Router,
    private _modal: NgbModal,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  addSegment() {
    let newSegment = {
      segmentCode: this.getFormControllerIdSegment.value,
      segmentDescription: this.getFormControllerName.value,
      flagActivation: false,
      updateDateTime: 0
    }
    if (!this.segments.some(item => item.segmentCode == newSegment.segmentCode)) {
      this.spinner.show();
      this.backlogParametrizacionRepository.addSegments(newSegment)
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this._route.navigateByUrl('/', { skipLocationChange: true }).then(() => {

              this._route.navigate(['parametrizacion']);
              const alertModalRef = this._modal.open(AlertOneButtonComponent, { backdropClass: 'cbaf-modal-backdrop' });
              alertModalRef.componentInstance.message = 'Se agregó el segmento con éxito.';

            });
            this.closeModal();
          }, error: (error) => {
            this.spinner.hide();
            alert('Ocurrió un error inesperado');
          }
        })
    } else {
      alert('El ID ya se encuentra registrado, pruebe con otro ID');
    }

  }

  closeModal() {
    this.activeModal.close();
  }
  initForm() {
    this.formSegment = this._formBuilder.group(
      {
        formEngine: new UntypedFormControl(),
        formSegmentName: new UntypedFormControl('', [
          Validators.minLength(5),
          Validators.maxLength(50),
          //onlyLettersAndNumbers
        ]),
        formIdSegmento: new UntypedFormControl('', [
          Validators.minLength(5),
          Validators.maxLength(30),
          //onlyNumbers
          //Validators.pattern("^[0-9]*$"),
        ])
      }
    );
  }
}
