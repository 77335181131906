import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ModalModule } from './modal/modal.module';
import { ChartComponent } from './chart/chart.component';

import { DropdownComponent } from './dropdown/dropdown';
import { SwitchModule } from './ui/switch/switch.module';
import { TimeLineModule } from './ui/timeline/timeline.module';
import { CheckboxModule } from './ui/checkbox/checkbox.module';
import { FooterComponent } from './footer/footer.component';
import { AlertOneButtonComponent } from './popus/alert-one-button/alert-one-button.component';
import { PaginationViewExampleComponent } from './ui/pagination-view/pagination-view.component';
import { AlertCloseSessionComponent } from './popus/alert-close-session/alert-close-session.component';

const virsorModule = [
    ModalModule,
    TimeLineModule,
    SwitchModule,
    CheckboxModule
];

@NgModule({
    declarations: [
        ChartComponent,
        DropdownComponent,
        FooterComponent,
        AlertOneButtonComponent,
        PaginationViewExampleComponent,
        AlertCloseSessionComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        ...virsorModule,
    ],
    exports: [
        ...virsorModule,
        ChartComponent,
        DropdownComponent,
        FooterComponent,
        AlertCloseSessionComponent
    ]
})
export class VisorCommonModule { }
