<div class="row">
		<div class="col-md-12">
			<h4>{{ timeline.title }}</h4>
			<ul class="timeline">
				<li *ngFor="let item of timeline.timeLineItems">
					<table class="table table-bordered tablaComment" aria-label="Tabla de linea de tiempo">
						<thead>
							<tr><th>Analista</th>
								<th>Fecha</th>
								<th>Comentario</th>
								<th>Estado</th>
								<th>Canal de contacto</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{{item.idUser}}</td>
								<td>{{item.datetimeRegistry |date:'yyyy-MM-dd'}}</td>
								<td>{{item.comment}}</td>
								<td>{{item.stateAlert}}</td>
								<td>{{item.contactChannel}}</td>
							</tr>
						</tbody>
					</table>
				</li>
			</ul>
		</div>
	</div>
