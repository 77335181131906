import { HttpClientModule } from "@angular/common/http";
import { InjectionToken, NgModule } from "@angular/core";
import { LoginRepository } from "../interfaces/login.repository";
import { LoginHttpRepository } from "./login-http.repository";

export const LOGIN_REPOSITORY = new InjectionToken<LoginRepository>('LoginRepository');

@NgModule({
  providers: [
    {
      provide: LOGIN_REPOSITORY,
      useClass: LoginHttpRepository,
    },
  ],
  imports: [
    HttpClientModule,
  ],
})
export class LoginRepositoryModule { }