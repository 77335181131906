export const environment = {
  clientId: 'f56176b7-db13-4c0a-92a1-692ff679b781',
  authority: 'https://login.microsoftonline.com/5d93ebcc-f769-4380-8b7e-289fc972da1b',
  production: true,
  API_URL_REPORT_MANAGEMENT: '/ux-cbaf-report-management-v1/channel/cbaf/v1',
  API_URL_FRAUD_ALERT_MANAGEMENT: '/ux-cbaf-alerts-v1/channel/cbaf/v1',
  API_URL_APLICATION_PARAMETER_MANAGEMENT: '/ux-cbaf-application-parameter-management-v1/channel/cbaf/v1',
  redirectUri: 'https://cbafcert.bcpperu.com/',
  clientIdCAS: 'ef8e1b62-bb02-4c8e-94fe-2e57c3ee9d0f',
  clientSecretCAS: '2b4102c1-d3c9-41de-a7c5-6795f0911a87',
  baseUrlCAS: 'https://authservercert.viabcp.com/',
  containerName: 'data'
}
