import { NgModule } from "@angular/core";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedComponentsModule } from "./components/shared-components.module";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { AuthorizationFileRepositoryModule } from "./repositories/authorization-file/repository/authorization-file-repository.module";
import { LoginRepositoryModule } from "./repositories/login/repository/login-http-repository.module";
import { VisorCommonModule } from "../common/common.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedComponentsModule,
        SharedPipesModule,
        LoginRepositoryModule,  
        AuthorizationFileRepositoryModule,
        VisorCommonModule        
    ],
    declarations: [],
    exports: [
        SharedComponentsModule, 
        SharedPipesModule,
        LoginRepositoryModule,
        AuthorizationFileRepositoryModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }