<h1>Parámetros</h1>
<div class="col-sm-12 select-container">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="'tab-a'">
      <a ngbNavLink>Envio de mensaje por segmento</a>
      <ng-template ngbNavContent>
        <visor-envio-mensaje-segmento></visor-envio-mensaje-segmento>
      </ng-template>
    </li>
    <li [ngbNavItem]="'tab-b'">
      <a ngbNavLink>Mensaje para FICO</a>      
      <ng-template ngbNavContent>
        <visor-mensaje-fico></visor-mensaje-fico>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>