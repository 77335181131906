import { Injectable } from '@angular/core';
import { CIAMApiResponse } from '../../core/model/api-response.model';
// import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private sessionStorageKey = '_cbaf_token';
  private sessionStorageTTL = '_cbaf_token_TTL';
  private tokenAzure = '_token_azure';
  private expiresInAzure = '_expires_in_azure';
  private tokenCAS = '_token_cas';
  private refreshCAS = '_refresh_cas';
  private expiresInCAS = '_expires_in_cas';
  private startedCAS = '_started_cas';
  private clientIp = '_client_ip';
  private userCode = '_user_code';

  saveStorage(state: CIAMApiResponse) {
    sessionStorage.setItem(this.sessionStorageTTL, state.expires_in.toString());
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(state));
  }

  removeStorage() {
    sessionStorage.removeItem(this.sessionStorageKey);
    sessionStorage.removeItem(this.sessionStorageTTL);
  }

  isValid() {
    return sessionStorage.getItem(this.sessionStorageTTL);
  }

  getTokenFromStore(): CIAMApiResponse {
    const obj: CIAMApiResponse = JSON.parse(sessionStorage.getItem(this.sessionStorageKey));
    return obj;
  }

  saveTokenAzure(token) {
    sessionStorage.setItem(this.tokenAzure, token);
  }

  getTokenAzure() {
    return sessionStorage.getItem(this.tokenAzure);
  }

  saveExpiresInAzure(token) {
    sessionStorage.setItem(this.expiresInAzure, token);
  }

  getExpiresInAzure() {
    return sessionStorage.getItem(this.expiresInAzure);
  }

  saveTokenCas(token) {
    sessionStorage.setItem(this.tokenCAS, token);
  }

  getTokenCas() {
    return sessionStorage.getItem(this.tokenCAS);
  }

  saveRefreshCAS(token) {
    sessionStorage.setItem(this.refreshCAS, token);
  }

  getRefreshCAS() {
    return sessionStorage.getItem(this.refreshCAS);
  }

  saveExpiresInCAS(time) {
    sessionStorage.setItem(this.expiresInCAS, time);
  }

  getExpiresInCAS() {
    return sessionStorage.getItem(this.expiresInCAS);
  }

  saveStartedCAS() {
    sessionStorage.setItem(this.startedCAS, Math.floor(new Date().getTime() / 1000).toString());
  }

  getStartedCAS() {
    return sessionStorage.getItem(this.startedCAS);
  }

  saveClientIp(ip) {
    sessionStorage.setItem(this.clientIp, ip);
  }

  getClientIp() {
    return sessionStorage.getItem(this.clientIp);
  }
  saveUserCode(code) {
    sessionStorage.setItem(this.userCode, code);
  }

  getUserCode() {
    return sessionStorage.getItem(this.userCode);
  }
}
