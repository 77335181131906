<h1>Parámetros</h1>
<div class="col-sm-12 select-container">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="'tab-a'">
      <a ngbNavLink>Envio de mensaje por segmento</a>
      <ng-template ngbNavContent>
        <visor-envio-mensaje-segmento></visor-envio-mensaje-segmento>
      </ng-template>
    </li>

    <!-- <ngb-tab title="Tiempo de espera">
      <ng-template ngbTabContent>
        <visor-tiempo-espera></visor-tiempo-espera>
    </ng-template>
  </ngb-tab> -->

    <li [ngbNavItem]="'tab-b'">
      <a ngbNavLink>Mensaje para FICO</a>      
      <ng-template ngbNavContent>
        <visor-mensaje-fico></visor-mensaje-fico>
      </ng-template>
    </li>
    <!-- <ngb-tab title="Configurar prioridad">
      <ng-template ngbTabContent>
        <visor-configurar-prioridad></visor-configurar-prioridad>
      </ng-template>
    </ngb-tab> -->
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>