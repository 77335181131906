export enum ENDPOINTS {

  LOGIN_GET_ANALYST_CODE = '/users/codes',
  
  DASHBOARD_PENDING = '/chart/1',
  DASHBOARD_CATEGORIES = '/chart/2',
  DASHBOARD_MOTOR = '/chart/3',

  DASHBOARD_ALERT_DAY_REPLY = '/dashboard-date-amount/2',
  DASHBOARD_ALERTS_GENERATED = '/dashboard-date-quantity/1',
  DASHBOARD_ALERTS_BYENGINE = '/dashboard-date-quantity/2',
  DAHSBOARD_ALERTS_BYCHANEL = '/dashboard-date-quantity/3',
  DAHSBOARD_ALERTS_BYTAIL = '/dashboard-date-quantity/4',
  DASHBOARD_ALERTS_RANGE_AMOUNT = '/dashboard-range-amount/1',
  DASHBOARD_ALERT_REPLY_RETRY = '/dashboard-range-amount/2',
  DASHBOARD_ALERTS_RANGE_MIN = '/dashboard-range-quantity/1',
  DASHBOARD_ALERTS_RANGE_REPLY = '/dashboard-range-quantity/2',
  DASHBOARD_ALERT_REPLY_TIMERANGE = '/dashboard-range-quantity/3',
  DASHBOARD_ALERT_REPLY_TIMERANGE_FILTER = '/dashboard-range-quantity/4',
  DASHBOARD_RETRY_BY_RANGE_TIME = '/dashboard-range-quantity/4',

  ALERTS_COUNT = '/alerts/count',
  FRAUDALERT_V1_ALERTS = '/alerts',
  FRAUDALERT_V1_ACTIONS = '/alerts',
  FRAUDALERT_V1_TRACKING = '/trackings',
  FRAUDALERT_V1_NOTIFICATIONS = '/alerts/notify',
  FRAUDALERT_V1_PREVIEW = '/alerts/preview',
  FRAUDALERT_V1_PREVIEW_UNBLOCKED = '/alerts/block',

  MASSIVE_QUALIFICATION_VRM = '/alerts/rate-only-vrm',
  MASSIVE_QUALIFICATION = '/alerts/close',

  REPORT_DOWNLOAD_EXCEL = '/report',
  REPORT_ALERT = '/report-page/alert',
  REPORT_FICO_VRM = '/report-page/request-control-fico-vrm',
  REPORT_ALERT_TRAKING = '/report-page/tracking',
  ALERTS_BY_FILTER = '/report/alert-group',

  CBAF_USERS = '/users',
  MASSIVE_ALERTS = '/users/upload',
  REMOVE_USERS = '/users',
  USERS_LOGINS = '/users/logins',
  USERS_LOGOUT = '/users/logout',
  
  PARAMETERS_MAIL_GET = '/email-messages',
  PARAMETERS_MAIL_PUT = '/email-messages',
  PARAMETERS_SEGMENTS = '/segments',
  PARAMETERS_SEGMENT_ADD = '/segments',
  PARAMETERS_SEGMENT_UPDATE = '/segments',

  RULES_GETRULES = '/rules',
  RULES_UPDATE_RULE = '/rules',

  FILE_AUTHORIZATION = '/authorization-file-token'
}