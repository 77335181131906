import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupConfirmacionComponent } from '../popup-confirmacion/popup-confirmacion.component';
@Component({
  selector: 'visor-tiempo-espera',
  templateUrl: './tiempo-espera.component.html',
  styleUrls: ['./tiempo-espera.component.scss']
})
export class TiempoEsperaComponent implements OnInit {

  public tiempoEspera:any;
  constructor(
    private _modal: NgbModal,
  ) { }

  ngOnInit() {
  }

  guardarTiempoService(){
  }
  guardar(){
    const alertModalRef = this._modal.open(PopupConfirmacionComponent, {backdropClass: 'cbaf-modal-backdrop'});
            alertModalRef.componentInstance.mensaje='¿Seguro que quiere guardar el tiempo de espera?';
            alertModalRef.componentInstance.parametros=this.tiempoEspera;
            alertModalRef.componentInstance.accion=this.guardarTiempoService;
  }
}
