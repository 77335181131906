<div class="container">
  <br><br>
  <div class="card w-75">
    <div class="card-body">
      <h5 class="card-title">Tiempo de espera</h5>
      <p class="card-text">Se configura el tiempo que se espera por una respuesta del cliente.</p>
    </div>
  </div>
  <br>
  <div>Tiempo de espera actual:<span class="badge badge-warning">5:00</span></div>
    <div class="form-group row no-gutters"><br>
        <label for="timeOut">Tiempo de espera:  </label>
        <input type="time" [(ngModel)]="tiempoEspera">
        <button class="btn btn-primary" (click)="guardar()">Guardar</button>
      </div>
</div>
