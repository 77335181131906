import { Component, ViewChild, ElementRef, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentHandlerService } from '../../services/DocumentHandler.service';

@Component({
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  errorList: any[] = [];
  confirmationEmmiter = new EventEmitter();
  constructor(
    private activeModal: NgbActiveModal,
    private _documentHandlerSrv: DocumentHandlerService
  ) {
    if (this.opts.excelFile) this.buildErrors();
  }
  get opts() {
    return ModalComponent.opts;
  }
  static opts: IModalOptions;

  static setModalType(opts: IModalOptions) {
    ModalComponent.opts = {
      title: opts.title,
      subTitle: opts.subTitle,
      content: opts.content,
      disclamer: opts.disclamer,
      modalType: opts.modalType,
      confirmation: opts.confirmation,
      excelFile: opts.excelFile ? opts.excelFile : false
    };
  }

  closeModal(selectedAction: string = '') {
    this.activeModal.close();
    this.confirmationEmmiter.emit(selectedAction);
  }
  download() {
    const HTMLTable = document.getElementById('error_excel');
    this._documentHandlerSrv.exportSimpleXLSX(HTMLTable, 'VALIDACIONES_EXCEL');
  }
  buildErrors() {
    let array = Array.from(this.opts.content);
    this.errorList = [];
    array.forEach(element => {
      let splited = element.split('. ');
      let position = splited.pop();
      let validations = [];
      splited.forEach((e, index) => {
        if (index < splited.length) validations.push(e);
      });
      this.errorList.push({
        position: position,
        errors: validations
      });
    });
  }
}
export interface IModalOptions {
  title?: string;
  subTitle?: string;
  content?: string[] | string;
  disclamer?: string;
  modalType?: string;
  confirmation?: boolean;
  excelFile?: boolean;
}
export class MODAL_TYPES {
  static MODAL_SUCCESS = {
    title: 'Guardado Correcto',
    subTitle: '',
    content: '',
    disclamer: 'Información guardada satisfactoriamente.',
    modalType: 'simple'
  };
  static MODAL_FAILED = {
    title: 'Ocurrió un Error',
    subTitle: '',
    content: '',
    disclamer: 'Se generó un error al procesar la solicitud. Inténtelo nuevamente.',
    modalType: 'simple'
  }
}
