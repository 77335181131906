import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SplashComponent } from './splash/splash.component';
import { authGuard} from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'splash',
        pathMatch: 'full',
      },
      {
        path: 'splash',
        component: SplashComponent,
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard-backlog/dashboard-backlog.module').then(m => m.DashboardBacklogModule),//loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'reportes',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportModule),
      },
      {
        path: 'alertas-agrupadas',
        loadChildren: () => import('./pages/group-alert/group-alert.module').then(m => m.GroupAlertModule),
      },
      {
        path: 'consulta-alertas-agrupadas/:showPending',
        loadChildren: () => import('./pages/group-alert/group-alert.module').then(m => m.GroupAlertModule),
      },
      {
        path: 'crear-regla',
        loadChildren: () => import('./pages/crear-regla/crear-regla.module').then(m => m.CrearReglaModule),
      },
      {
        path: 'mantenimiento-reglas-vrm',
        loadChildren: () => import('./pages/mantenimiento-reglas/mantenimiento-reglas.module').then(m => m.MantenimientoReglasModule),
      },
      {
        path: 'mantenimiento-parametros',
        loadChildren: () => import('./pages/mantenimiento-parametros-generales/mantenimiento-parametros-generales.module').then(m => m.MantenimientoParametrosGeneralesModule),
      },
      {
        path: 'parametrizacion',
        loadChildren: () => import('./pages/backlog-parametrizacion/backlog-parametrizacion.module').then(m => m.BacklogParametrizacionModule),//loadChildren:() => import('./pages/parametrizacion/parametrizacion.module').then(m => m.ParametrizacionModule),//loadChildren: './pages/parametrizacion/parametrizacion.module#ParametrizacionModule',
      },
      {
        path: 'calificacion-masiva',
        loadChildren: () => import('./pages/alertas-masivas/alertas-masivas.module').then(m => m.AlertasMasivasModule),
      },
      // {
      //   path: 'priorizacion-listas',
      //   loadChildren: './pages/priorizacion-listas/priorizacion-listas.module#PriorizacionListasModule',
      // },
      {
        path: 'carga-usuarios',
        loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule),
      },
    ]
  },
  {
    path: 'validate-cas',
    loadChildren: () => import('./validate-cas/validate-cas.module').then(m => m.ValidateCasModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }