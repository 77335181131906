import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { StoreService } from "../store/store.service";
import { ENV, getENV } from "../../shared/helpers/DetectENV";
@Injectable({
  providedIn: "root",
})
export class TokenCasService {
  constructor(private networking: HttpClient,
    private storeService: StoreService) {
  }

  validateCAS(): boolean {
    const accesstoken = this.storeService.getTokenCas();
    const started = Number(this.storeService.getStartedCAS() || 0);
    const expires = Number(this.storeService.getExpiresInCAS() || 0);
    if (accesstoken && started > 0 && expires > 0) {

      return true;
    }

    return false;
  }
  
  getCodeOC() {
    const authserver = `${environment.baseUrlCAS}`;
    const cas = 'cas/oidc/';
    const redirect_uri = `${window.origin}/validate-cas`;
    const state = 'ha3JDet1d6nDF23emECWErVaBQ7McJyiy48uDpzhNsX';
    const client_id = `${environment.clientIdCAS}`;
    const code_challenge_method = 'S256';
    const code_challenge = 'ZTA1Y2M2NzBlZDRjYmI2N2ZiNzVmZGU4YWNhZTAxNGQ5YjJmM2NkN2U2YjUxMjg4MjRkYjk5ZTY5NDBjMWU2Yw';
    const nonce = 'BzIOkT7rSogLyCQ36swwTDqSPI879tUZQvnIa9YVzll';
    
    const env = getENV();
    let scope = 'ux-cbaf-alerts-v1+ux-cbaf-application-parameter-management-v1+ux-cbaf-report-management-v1';
    if (env == ENV.PROD)
      scope = 'ux-cbaf-alerts-v1+ux-cbaf-application-parameter-management-v1+ux-cbaf-report-management-v1+bs-notification-v3';
    
    const request = `${authserver}${cas}authorize?response_type=code`
      + `&redirect_uri=${redirect_uri}`
      + `&state=${state}`
      + `&client_id=${client_id}`
      + `&scope=${scope}`
      + `&code_challenge_method=${code_challenge_method}`
      + `&code_challenge=${code_challenge}`
      + `&nonce=${nonce}`;

    return request;
  }

  getAccessToken(codeOC: string): Observable<any> {
    const authserver = `${environment.baseUrlCAS}`;
    const cas = 'cas/oidc/';
    const grant_type = 'authorization_code';
    const redirect_uri = `${window.origin}/validate-cas`;
    const client_id = `${environment.clientIdCAS}`;
    const code_verifier = 'BhdvyyAnMuIIBl1krCTqPyts8SDAwsgTFREd6HsgKMGoWCE2RFGu2uznkelOITRJJaImewjo67tKqUPivAcS3Ah4lc8GjR33PzdTIqRGeOViqssqnnOcQu92QNfJZz4U';

    const request = `${authserver}${cas}accessToken?`
      + `code=${codeOC}`
      + `&grant_type=${grant_type}`
      + `&redirect_uri=${redirect_uri}`
      + `&client_id=${client_id}`
      + `&code_verifier=${code_verifier}`

    return this.networking.post(request, {})
  }

  getRefreshToken(): Observable<any> {
    const authserver = `${environment.baseUrlCAS}`;
    const cas = 'cas/oidc/';
    const grant_type = 'refresh_token';
    const client_id = `${environment.clientIdCAS}`;
    const client_secret = `${environment.clientSecretCAS}`;
    const refresh_token = this.storeService.getRefreshCAS();

    const request = `${authserver}${cas}accessToken?`
      + `grant_type=${grant_type}`
      + `&client_id=${client_id}`
      + `&client_secret=${client_secret}`
      + `&refresh_token=${refresh_token}`

    return this.networking.post(request, {})
  }
}
