import { Component, OnInit, inject, ɵConsole } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActualizarMensajeComponent } from '../actualizar-mensaje/actualizar-mensaje.component';
import { FormControl, FormGroup } from '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { AlertOneButtonComponent } from '../../../common/popus/alert-one-button/alert-one-button.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { BACKLOG_PARAMETRIZACION_REPOSITORY } from '../repository/backlog-parametrizacion-repository.module';

@Component({
  selector: 'visor-mensaje-fico',
  templateUrl: './mensaje-fico.component.html',
  styleUrls: ['./mensaje-fico.component.scss', '../../../../../node_modules/dragula/dist/dragula.css'],
  providers: [DragulaService]
})
export class MensajeFicoComponent implements OnInit {
  private readonly backlogParametrizacionRepository = inject(BACKLOG_PARAMETRIZACION_REPOSITORY);
  formFico: FormGroup;
 
  public selectType = false;


  public campos = [
    {
      variable: '$nombre',
      isUse: false
    },
    {
      variable: '$fecha',
      isUse: false
    },
    {
      variable: '$hora',
      isUse: false
    },
    {
      variable: '$estadotransaccion',
      isUse: false
    },
    {
      variable: '$tarjeta',
      isUse: false
    },
    {
      variable: '$monto',
      isUse: false
    },
    {
      variable: '$moneda',
      isUse: false
    },
    {
      variable: '$lugar',
      isUse: false
    },
    {
      variable: '$tipotarjeta',
      isUse: false
    },
    {
      variable: '$intento',
      isUse: false
    },
    {
      variable: '$tipotransaccion',
      isUse: false
    }
  ];
  public mensajeEditado = {
    tipo: 0,
    label: '',
    mensaje: '',
    keyTypeTransaction: ''
  }
  public listaTipoMensaje = [
    {
      tipo: 1,
      label: 'Retiros en ATM de otras redes.',
      mensaje: '<p>Mensaje ATM</p>',
      keyTypeTransaction: 'mail_msg_atm'
    },
    {
      tipo: 2,
      label: 'Compras Ecommerce',
      mensaje: '<p>Mensaje CI</p>',
      keyTypeTransaction: 'mail_msg_ecommerce'
    },
    {
      tipo: 3,
      label: 'Recurrentes y MONTO',
      mensaje: '<p>Mensaje CG</p>',
      keyTypeTransaction: 'mail_msg_recurrent'
    },
    {
      tipo: 4,
      label: 'Card On File',
      mensaje: '<p>Mensaje CG</p>',
      keyTypeTransaction: 'mail_msg_affiliation'
    },
    {
      tipo: 5,
      label: 'Consumo después de la afiliación',
      mensaje: '<p>Mensaje CG</p>',
      keyTypeTransaction: 'mail_msg_after_affiliation'
    },
    {
      tipo: 6,
      label: 'Compra con tarjeta presente',
      mensaje: '<p>Mensaje CG</p>',
      keyTypeTransaction: 'mail_msg_card_present'
    },
    {
      tipo: 7,
      label: 'Transacción Yape interoperable',
      mensaje: '<p>Mensaje CG</p>',
      keyTypeTransaction: 'mail_msg_yape'
    },
  ]
  constructor(
    private modal: NgbModal,
    private modalConfig: NgbModalConfig,
    private dragulaService: DragulaService,
    private _ngxSpinnerService: NgxSpinnerService,
    private _modal: NgbModal,
  ) {
    this.dragulaService.createGroup('bag-items', {
      removeOnSpill: true,
      copy: true
    });
    
    this.formFico = new FormGroup({
      mensaje: new FormControl('')
  });
  }

  ngOnInit() {
  }
  actualizarMensajeFico() {
    this.modal.open(ActualizarMensajeComponent);
  }
  selectTipoMensaje(i) {
    this.selectType = true;
    this.mensajeEditado.tipo = this.listaTipoMensaje[i].tipo;
    this.mensajeEditado.label = this.listaTipoMensaje[i].label;
    this.mensajeEditado.mensaje = this.listaTipoMensaje[i].mensaje;
    this.mensajeEditado.keyTypeTransaction = this.listaTipoMensaje[i].keyTypeTransaction;
    this.formFico.get('mensaje').setValue(this.listaTipoMensaje[i].mensaje);
    this.getMail(this.listaTipoMensaje[i].keyTypeTransaction);
  }
  insertarCampo(i) {
    this.mensajeEditado.mensaje += ' ' + this.campos[i].variable;
  }
  cancelar() {
    this.selectType = false;
  }
  getMail(transactionChannel) {
    this._ngxSpinnerService.show();
    this.backlogParametrizacionRepository.getMail(transactionChannel)
      .subscribe({
        next: (res: any) => {
          this.mensajeEditado.mensaje = res.parameterValue;
          this.formFico.get('mensaje').setValue(res.parameterValue);
          this.extraerVariable(this.mensajeEditado.mensaje);
          this._ngxSpinnerService.hide();
        }, error: (error) => {
          alert('Ocurrio un error inesperado');
          this._ngxSpinnerService.hide();
        }
      })
  }

  upsateEmail(json) {
    this._ngxSpinnerService.show();
    if (this.mensajeEditado.mensaje.startsWith('<p>')) {
      let doc = new DOMParser().parseFromString(this.mensajeEditado.mensaje, 'text/html');
      this.mensajeEditado.mensaje = doc.querySelector('p').textContent;
      this.formFico.get('mensaje').setValue(doc.querySelector('p').textContent);
    }
    let texto = {
      "transactionChannel": this.mensajeEditado.keyTypeTransaction,
      "parameterValue": this.mensajeEditado.mensaje
    }
    this.backlogParametrizacionRepository.updateEmail(texto)
      .subscribe({
        next: (res) => {
          alert('Se Actualizó el email');
          this._ngxSpinnerService.hide();
        }, error: (error) => {
          this._ngxSpinnerService.hide();
          this._modal.open(AlertOneButtonComponent, { backdropClass: 'cbaf-modal-backdrop' });
        }
      })
  }

  extraerVariable(text) {
    this.campos.forEach((item, index) => {
      if (text.includes(item.variable)) this.campos[index].isUse = true;
      else this.campos[index].isUse = false;
    })
  }
}
