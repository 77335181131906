<bcp-loading img="/assets/img/logos/bcp-dark-white.svg" [open]="spinner"></bcp-loading>tr
<nav class="navbar fixed-top shadow navbar-expand-lg"><a class="navbar-brand"><img src="assets/images/logo_bcp_n.svg"
      width="90" height="23" alt="BCP" />VISOR - CBAF</a><button class="navbar-toggler" type="button"
    data-toggle="collapse" data-target="#navbarNavDemo1" aria-controls="navbarNavDemo1" aria-expanded="false"
    aria-label="Toggle navigation"><span class="icon bcp-menu-r"></span></button>
  <div class="collapse navbar-collapse" id="navbarNavDemo1">
    <ul class="navbar-nav">
      <li class="nav-item"> <a class="nav-link" href="javascript:void(0);"><em class="icon bcp-man-b"></em>Tiempo
          restante de
          la sesión: {{timeLoginMin}}:{{timeLoginSec}}</a></li>
    </ul>
    <ul class="navbar-nav">
      <li class="nav-item"><a class="nav-link" href="javascript:void(0);"><em class="icon bcp-man-b"></em>Usuario
          ({{ userName }})</a></li>
      <form class="form-inline"><button class="btn btn-sm btn-primary" (click)="logout()"><em
            class="icon bcp-sign-out-r"></em>Cerrar Sesi&oacute;n</button>
      </form>
    </ul>
  </div>
</nav>