import { Component, Input } from '@angular/core';
import { TimeLine } from './timeline.model';

@Component({
  selector: 'visor-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {

  @Input() timeline: TimeLine;

}
