import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ActionsService {
    constructor() { }
    
    public getCardActions() {
        return [
        {
            value: 'Bloqueo temporal',
            label: 'Bloqueo Temporal'
        },
        {
            value: 'Bloqueo definitvo',
            label: 'Bloqueo Definitvo'
        }
        ];
    }
    
    public getFraudActions() {
        return [
        'Cuenta usada para  fraude/estafa',
        'Clonación',
        'Robo/Pérdida de Celular',
        'Tarjeta Perdida',
        'Tarjeta Robada',
        'Cambiazo',
        'Suplantación de Identidad',
        'UFNC - Internet',
        'Phishing',
        'Vishing',
        'Tarjeta no recibida',
        'Posible Entorno',
        'Acción Preventiva POC'
        ];
    }
}