import {
  BcpAuthAzureADStrategy,
  BcpAuthContext,
  Configuration,
  RedirectRequest
} from '@bcp/js-authentication';
import { environment } from '../../../../environments/environment';

export function AuthConfigurationFactory() {
  const config: Configuration = {
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: window.location.origin,
    }
  };

  const loginRequest: RedirectRequest = {
    // scopes: ['openid', 'profile', 'User.Read']
    scopes: [environment.clientId + '/.default']
  };
  const authAzureADStrategy = new BcpAuthAzureADStrategy(config, loginRequest);

  const authContext = BcpAuthContext.getInstance();
  authContext.setStrategy(authAzureADStrategy);

  return authContext;
}