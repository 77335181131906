import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'visor-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() label: string;
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('checkbox', { static: true }) input: ElementRef;

  toggleSwitch() {
    this.input.nativeElement.checked = !!this.input.nativeElement.checked;
    this.statusChange.emit(this.input.nativeElement.checked);
  }
}
