import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'visor-actualizar-mensaje',
  templateUrl: './actualizar-mensaje.component.html',
  styleUrls: ['./actualizar-mensaje.component.scss']
})
export class ActualizarMensajeComponent {
  public acciones = [
    {
      value: 'descarte',
      label: 'Descarte'
    },
    {
      value: 'buena',
      label: 'Buena'
    },
    {
      value: 'pendiente',
      label: 'Pendiente'
    },
    {
      value: 'fraude',
      label: 'Fraude'
    }
  ];
  public accionesTarjeta = [];
  public accionesFraude = [];
  public tipoMensaje: [
    {
      value: 1,
      label: 'Tipo 1',

    }
  ]
  constructor(
    private modalActive: NgbActiveModal
  ) { }
  close() {
    this.modalActive.close();
  }
}
