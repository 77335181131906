import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, range } from 'rxjs';
import { DOCUMENTS } from '../enums/documents.enum';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';

const API_URL = `${''}`;

// const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_TYPE = DOCUMENTS.xlsx;
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class DocumentHandlerService {

    constructor(private http: HttpClient) { }

    /**
     * This function takes the param data, transform it to the file type required.
     * @param data 
     */
    downloadDocument(content: string, name: string, type?: string): void {
        const excelFileType = DOCUMENTS[type];
        const linkSource = `data:${excelFileType};base64,${content}`;
        fetch(linkSource)
            .then(res => res.blob())
            .then(blob => {
                const link = window.document.createElement("a");
                const fileName = `${name}.${type}`;
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
    }

    /**
     * This method returns an array of fileDocuments in base64, with aditional info(name, type, format)
     * @param files
     */
    transformToBase64(files: Array<{ files: FileList, type: string }>):
        Promise<Array<{ name: string, type: string, format: string, file: string }>> {
        return new Promise(async (resolve, reject) => {
            let fileDocuments = [];
            for (let fk in files) {
                for (let file in files[fk].files) {
                    if (files[fk].files[file] instanceof File) {
                        let fileDocument = await this.loadEnd(files[fk], file);
                        fileDocuments.push(fileDocument);
                    }
                }
            }
            resolve(fileDocuments);
        });
    }
    transformToArrayOfArrays(files: Array<{ files: FileList }>):
        Promise<Array<string>> {
        return new Promise(async (resolve, reject) => {
            let fileDocuments = [];
            for (let fk in files) {
                for (let file in files[fk].files) {
                    if (files[fk].files[file] instanceof File) {
                        let fileDocument = await this.loadEndAA(files[fk], file);
                        fileDocuments.push(fileDocument);
                    }
                }
            }
            resolve(fileDocuments);
        });
    }

    private loadEndAA(item, file): Promise<any> {
        type AOA = any[][];
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];
                resolve(
                    <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }))
                );
            }
            reader.readAsBinaryString(item.files[file]);
        });
    }


    private loadEnd(item, file): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = x => resolve({
                name: (item.files[file].name).split('.').shift(),//file name
                type: item.type,
                format: (item.files[file].name).split('.').pop(),//file extension
                file: (<string>reader.result).replace(/^data:.+;base64,/, '')//file content in base64
            });
            reader.readAsDataURL(item.files[file]);
        });
    }
    public isValidDocument(files: FileList, inputType: string) {
        //there's some troubles getting the mimetype of type msg, that's why we'll validate msg files by using their extensions
        if (inputType === 'tica') {
            return this.validate(files, ['msg']);
        } else if (inputType === 'documents') {
            return this.validate(files, ['jpg', 'pdf', 'msg']);
        }
        return false;
    }

    private validate(files: FileList, availables: string[]) {
        const avb = new Set(availables);
        for (let key = 0; key < files.length; key++) {
            const extension = (files[key].name.split('.').pop()).toLowerCase();
            if (!avb.has(extension)) {
                return false;
            }
        }
        return true;
    }
    public exportSimpleXLSX(table: HTMLElement, fileName: string) {
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, { raw: true });
        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        let ranges: any = [];
        if (fileName == 'TRACKING') {
            ranges.push(
                // { s: { r: 1, c: 1 }, e: { r: 1000, c: 1 }, format: 'n', specificFormat: '00000000' },
                { s: { r: 1, c: 1 }, e: { r: 1000, c: 1 }, format: 's' },
                { s: { r: 1, c: 2 }, e: { r: 1000, c: 2 }, format: 's' }
            );
        } else if (fileName !== 'TRACKING') {
            ranges.push(
                { s: { r: 1, c: 1 }, e: { r: 1000, c: 1 }, format: 's' },
                { s: { r: 1, c: 8 }, e: { r: 1000, c: 8 }, format: 's' },
                { s: { r: 1, c: 12 }, e: { r: 1000, c: 12 }, format: 's' }
            );
        }
        ranges.forEach(range => {
            for (let R = range.s.r; R <= range.e.r; ++R) {
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
                    if (cell) {
                        if (range.specificFormat) {
                            cell.z = range.specificFormat;
                        }
                        cell.t = range.format;
                    }
                }
            }
        });
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        let today = new Date(),
            time = today.toTimeString().split(':').join('').substr(0, 4),
            timestamp = this.getDate('dd_mm_yyyy', today) + '_' + time;
        XLSX.writeFile(wb, fileName + '_REPORTE_' + timestamp + EXCEL_EXTENSION);
    };

    public getDate(mode, userdate) {
        const dte = userdate || new Date(),
            d = dte.getDate().toString(),
            m = (dte.getMonth() + 1).toString(),
            yyyy = dte.getFullYear().toString(),
            dd = (d.length < 2) ? '0' + d : d,
            mm = (m.length < 2) ? '0' + m : m;
        switch (mode) {
            case 'dd_mm_yyyy': return dd + '_' + mm + '_' + yyyy;
            case 'yyyymmdd': return yyyy + mm + dd;
            default: return dte;
        }
    }
}