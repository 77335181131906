
export const VALIDATIONS = {
  REQUIRED: 'required',
  LENGTH: 'length',
  MINLENGTH: 'minLength',
  MAXLENGTH: 'maxLength',
  EMAIL: 'email',
  NUMERIC: 'numeric',
  TEXT: 'text',
  ALPHANUMERIC: 'alphanumeric',
  EXACTVALUES: 'exactValues',
  SPECIFIC_FORMAT: 'specificFormat',
  ONLY_LETTERS: 'onlyLetters',
  DATE: 'date'
};
export const FORMATS = {
  IDC_FORMAT: '<ID>-<TipoDocumento>',
  EMAIL_FORMAT: 'USUARIO@DOMINIO',
  AMOUNT_FORMAT: '0000000.00'
};
export const FILE_NAME = {
  LIST_PRIORIZATION: 'PRIORIZACION_LISTAS',
  OBS_QUALIFICATION: 'OBSERV_CALIFICACION_CBAF_VRM',
  ERR_QUALIFICATION: 'ERRORES_CALIFICACION_CBAF_VRM',
  OBS_QUALIFICATION_VRM: 'OBSERV_CALIFICACION_VRM',
  ERR_QUALIFICATION_VRM: 'ERRORES_CALIFICACION_VRM'
}
export const MSG = {
  ERROR_ADD_PROVIDER: 'Ocurrió un error al agregar un proveedor',
  GENERIC_ERROR: 'Ocurrió un error',
  TRY_LATER: 'Inténtelo en unos momentos.',
  INVALID_DATA: 'Existen datos inválidos. Por favor verifica lo siguiente:',
  FILE_FEED: 'Seleccione archivo excel .xlsx',
  REQUIRED_INPUT: 'Campo Requerido. ',
  INVALID_FORMAT: 'Formato Incorrecto',
  INVALID_LENGHT: 'Longitud inválida.Debe contar con',
  INVALID_MINLENGHT: 'Verifique longitud mínima',
  INVALID_MAXLENGHT: 'Verifique longitud máxima',
  INVALID_EMAIL: 'E-Mail inválido. ',
  INVALID_NUMBER: 'Dato númerico inválido. ',
  INVALID_VALUE: 'Dato inválido. ',
  INVALID_TEXT_FORMAT: 'Formato inválido.',
  INVALID_DATE_FORMAT: 'Formato de fecha inválido',
  FILE_FEEDBACK_MSG: 'Por favor revisa el documento y vuelve a subirlo.',
  EMPTY_FILE_FEEDBACK: 'El archivo se encuentra vacío.',
  INVALID_FILE_FEEDBACK_MSG: 'El archivo no tiene el formato correcto.',
  INVALID_DATA_FILE_FEEDBACK_MSG: 'Información inválida. Verifica el documento.',
  FB_BRAND: '(solo VISA y/o MASTERCARD).',
  FB_CARDTYPE: '(solo Crédito y/o Débito).',
  FB_CURRENCY: '(solo Soles (PEN) y/o Dólares (USD)).',
  FB_TYPE_LIST: `(solo 'Lista_Negra', 'Lista_Blanca' ó 'Eliminar')`,
  EMPTY_SEARCH_CRITERIA: 'Seleccione el criterio de búsqueda.',
  EMPTY_RESPONSE: 'No hay registros de la búsqueda realizada.',
  EMPTY: 'No se encontraron registros.',
  EMPTY_FILTER_VALUE: 'Ingrese un valor de filtrado válido.',
  BLOCKED_ALERTS: 'Selección inválida. Las siguientes alertas están siendo gestionadas por otro colaborador: ',
  SUCCESS_UPDATE: 'Actualización exitosa, las alertas seleccionadas entraron nuevamente al flujo.',
  ALERT_SELECTION_WARNING: 'Debe Seleccionar una Alerta.',
  INVALID_ALPHANUMERIC: 'Verifique que el valor sea Alfanumérico.',
  OUTRANGE_MESSAGE: 'Los rangos de fechas no pueden ser mayores a la fecha actual.',
  INVALID_RANGE: 'La fecha fin debe ser mayor a la fecha de inicio.',
  INVALID_HOUR_RANGE: 'La hora final debe ser mayor a la hora inicial',
  INVALID_RANGE_MAX: 'El rango de fecha no puede ser mayor a dos días',
  INVALID_RANGE_SELECTION: 'Intervalo de fechas inválido.',
  INVALID_HOURS: 'Coloque un rango de hora correcto',
  INVALID_RANGE_HOURS: 'La hora inicial no puede ser mayor que la hora final',
  ALERT_BLOCKED: 'Ocurrió un error. Probablemente existe(n) alerta(s) bloqueada(s) por otro(a) usuario. Seleccione otra(s) alerta(s).',
  INVALID_TEXT_CONTENT: 'Texto inválido.Evite el uso de números, caracteres especiales y/o símbolos.',
  INVALID_CLIENT_IDC_FORMAT: `Formato inválido.(Formato: ${FORMATS.IDC_FORMAT}) donde ID: DNI, Carnet de Extranjería ó Pasaporte.`,
  INVALID_AMOUNT_FORMAT: `Formato inválido.(Formato: ${FORMATS.AMOUNT_FORMAT}, Máx.(7) enteros y (2) decimales.`,
  INVALID_HOUR_VALUE: `El dato Hora no puede ser 00:00. `,
  INVALID_DATE_VALUE: `El dato Fecha no puede ser 0. `,
  INVALID_AMOUNT_VALUE: `El dato Monto no puede ser 0. `,
  EMPTY_ALERTS: 'Realice una búsqueda para visualizar la información.',
  INVALID_CARD: 'Nro. de Tarjeta inválido.',
  INVALID_DOC: 'Nro. de Documento inválido.',
  INVALID_DATE: 'La Fecha ingresada no es válida.',
  EMPTY_USERS: 'No hay usuarios registrados en el sistema.',
  CONFIRMATION_DELETE: 'El acceso del usuario será removido de la aplicación.',
  CONFIRMATION_MSG: '¿Desea continuar?',
  SUCCESS: 'Información guardada satisfactoriamente.',
  EMPTY_PARAMETER_VALUE: 'El valor del parámetro no puede estar vacío.'
};
export const MOTORS = {
  LYNX: 'LYNX',
  VRM: 'VRM'
}
