import { Observable } from "rxjs";
import { SearchCodeResponse } from "../interfaces/login.interface";
import { LoginRepository } from "../interfaces/login.repository";
import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ENDPOINTS } from "../../../../core/services/endpoint.enum";
import { environment } from "../../../../../environments/environment";

@Injectable()
export class LoginHttpRepository implements LoginRepository{
    searchCode(): Observable<SearchCodeResponse> {
        return this.httpClient.get<any>(`${environment.API_URL_FRAUD_ALERT_MANAGEMENT}${ENDPOINTS.LOGIN_GET_ANALYST_CODE}`)
    }
    userLogin() {
        return this.httpClient.post<any>(`${environment.API_URL_FRAUD_ALERT_MANAGEMENT}${ENDPOINTS.USERS_LOGINS}`, {})
    }
    private readonly httpClient = inject(HttpClient);
}