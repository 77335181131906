<div class="card w-75">
  <div class="card-body">
    <h5 class="card-title">Envío de mensaje por segmento</h5>
    <p class="card-text">Seleccionar a que segmento se le envía mensaje.</p>
  </div>
</div>
<button class="btn btn-primary" (click)="addSegment()">Agregar segmento</button>
<table class="table table-hover" aria-label="Tabla segmento">
  <thead>
    <tr>
      <th>Envío de mensaje</th>
      <th>Código del segmento</th>
      <th>Descripción segmento</th>
      <th>Fecha de actualización</th>
      <th>Usuario</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let segmento of segmentos; let i = index ">
      <tr>
        <td>
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label [ngClass]="segmento.flagActivation?'btn btn-secondary active':'btn btn-secondary'"
              (click)="updateSegment(segmento,true)">
              <input type="radio" name="options" id="option1" checked> Activado
            </label>
            <label [ngClass]="!segmento.flagActivation?'btn btn-secondary active' :'btn btn-secondary'"
              (click)="updateSegment(segmento,false)">
              <input type="radio" name="options" id="option2"> Desactivado
            </label>
          </div>
        </td>
        <td>{{segmento.segmentCode}}</td>
        <td>{{segmento.segmentDescription}}</td>
        <td>{{segmento.updateDateTime}}</td>
        <td>{{segmento.updateUser}}</td>
        <td><input alt="input" class="btn btn-primary" src="../../../../assets/images/editicon.png" type="image"
            value="Editar Segmento" (click)="editSegment(segmento,i)"></td>
      </tr>
      <ng-container *ngIf="segmento.editEnable==true">
        <tr>
          <td><label>ID Segmento</label>
            <input class="form-control" type="text" [(ngModel)]="tempSegment.segmentCode"></td>
          <p class="alert alert-warning" role="alert" *ngIf="!tempSegment.segmentCode">El campo ID no puede estar
            vacío.</p>
            <p class="alert alert-warning" role="alert" *ngIf="tempSegment.segmentCode.length>30">El campo ID no puede
              ser mayor a 30.</p>
          <td><label>Descripción del segmento</label>
            <input class="form-control" type="text" [(ngModel)]="tempSegment.segmentDescription" (ngModelChange)="validateFormEdit()"></td>
          <p class="alert alert-warning" role="alert" *ngIf="onlyLettersAndNumbers(tempSegment.segmentDescription)">La
            descripción no puede tener caracteres especiales.</p>
          <p class="alert alert-warning" role="alert" *ngIf="tempSegment.segmentDescription.length>50">La descripción
            no puede tener más de 50 caracteres.</p>
          <p class="alert alert-warning" role="alert" *ngIf="tempSegment.segmentDescription.length<5">La descripción
            no puede tener menos de 5 caracteres.</p>
          <p class="alert alert-warning" role="alert" *ngIf="!tempSegment.segmentDescription">La descripción no puede
            estar vacía.</p>
          <td> <button type="submit"   [ngClass]="tempSegment.segmentaValid?'btn btn-primary':'btn btn-danger'" (click)="confirmEditSegment(i)">Aceptar</button></td>
          <td><input class="btn btn-primary" type="button" value="Cancelar" (click)="cancelUpdate(segmento,i)"></td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>