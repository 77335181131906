import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ENDPOINTS } from "../../../core/services/endpoint.enum";
import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { BacklogParametrizacionRepository } from "../interface/backlog-parametrizacion.repository";
@Injectable()
export class BacklogParametrizacionHttpRepository implements BacklogParametrizacionRepository{
    getSegments(): Observable<any[]> {
        return this.httpClient.get<any[]>(`${environment.API_URL_APLICATION_PARAMETER_MANAGEMENT}${ENDPOINTS.PARAMETERS_SEGMENTS}`)
    }
    addSegments(segment: any) {
        return this.httpClient.post(`${environment.API_URL_APLICATION_PARAMETER_MANAGEMENT}${ENDPOINTS.PARAMETERS_SEGMENT_ADD}`, segment)
    }
    updateSegment(segment: any) {
        return this.httpClient.post(`${environment.API_URL_APLICATION_PARAMETER_MANAGEMENT}${ENDPOINTS.PARAMETERS_SEGMENT_UPDATE}`, segment)
    }
    getMail(transactionChannel: any): Observable<any> {
        return this.httpClient.get(`${environment.API_URL_APLICATION_PARAMETER_MANAGEMENT}${ENDPOINTS.PARAMETERS_MAIL_GET}?transactionChannel=${transactionChannel}`);
    }
    updateEmail(json: any) {
        return this.httpClient.put(`${environment.API_URL_APLICATION_PARAMETER_MANAGEMENT}${ENDPOINTS.PARAMETERS_MAIL_PUT}`, json);    
    }
    private readonly httpClient = inject(HttpClient);
}