import { Injectable } from '@angular/core';
import { StoreService } from '../../providers/store/store.service';
import { getENV, ENV } from '../helpers/DetectENV';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {

  constructor(
    private storeService: StoreService
  ) { }
  getInterceptorHeadersWithOutToken(): { [name: string]: string | string[] } {
    const _token_azure = this.storeService.getTokenAzure();
    const _token_cas = this.storeService.getTokenCas();
    const _clientIp = this.storeService.getClientIp();
    const headers = {
      'caller-name': 'channel-cbaf-report-management',
      'app-code': 'FX',
      'X-Auth-Token': `${_token_azure}`,
      'Authorization': `Bearer ${_token_cas}`,
      'Content-Type': 'application/json',
      'request-date': moment().format('YYYY-MM-DDThh:mm:ss.SSSZZ'),
      'opn-number': '12345678',// Sólo compatible con versión de atlas 2.4.x
      // 'opn-number': this.getOpnNumberHeader(getENV()),
      'server-ip': '0.0.0.0'
    };
    return headers;
  }
  /*getOpnNumberHeader(env: string): string {
    switch (env) {
      case ENV.DEV: return '12345678';
      case ENV.CERT: return '12345678';
      case ENV.PROD: return '1234';
      case ENV.LOCAL: return '1234';
    }
  }*/
}
