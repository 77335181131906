import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'visor-actualizar-mensaje',
  templateUrl: './actualizar-mensaje.component.html',
  styleUrls: ['./actualizar-mensaje.component.scss']
})
export class ActualizarMensajeComponent implements OnInit {
  public acciones = [
    {
      value: 'descarte',
      label: 'Descarte'
    },
    {
      value: 'buena',
      label: 'Buena'
    },
    {
      value: 'pendiente',
      label: 'Pendiente'
    },
    {
      value: 'fraude',
      label: 'Fraude'
    }
  ];
  public accionesTarjeta = [
    {
      value: 'Bloqueo temporal',
      label: 'Bloqueo Temporal'
    },
    {
      value: 'Bloqueo definitvo',
      label: 'Bloqueo Definitvo'
    }
  ];
  public accionesFraude = [
    'Cuenta usada para  fraude/estafa',
    'Clonación',
    'Robo/Pérdida de Celular',
    'Tarjeta Perdida',
    'Tarjeta Robada',
    'Cambiazo',
    'Suplantación de Identidad',
    'UFNC - Internet',
    'Phishing',
    'Vishing',
    'Tarjeta no recibida',
    'Posible Entorno',
    'Acción Preventiva POC',

  ];
  public tipoMensaje: [
    {
      value: 1,
      label: 'Tipo 1',

    }
  ]
  constructor(
    private modalActive: NgbActiveModal
  ) { }

  ngOnInit() {
  }
  close() {
    this.modalActive.close();
  }
}
