import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { PagesComponent } from './pages/pages.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VisorCommonModule } from './common/common.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgChartsModule } from 'ng2-charts';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpHeaderInterceptor } from './core/interceptors/http.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SplashComponent } from './splash/splash.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BacklogParametrizacionModule } from './pages/backlog-parametrizacion/backlog-parametrizacion.module';
import { ValidateCasComponent } from './validate-cas/validate-cas.component';
import { HttpErrorInterceptor } from './core/interceptors/http.error.interceptor';
import { BcpAuthContext } from '@bcp/js-authentication';
import { AuthConfigurationFactory } from './shared/auth/auth/auth.factory';
import { AuthModule } from './shared/auth/auth/auth.module';
import { TokenCasService } from './providers/token-cas/token-cas.service';
import { TokenCasServiceMock } from './shared/mocks/tokenCasServiceMock';
import { AuthServiceMock } from './shared/mocks/authServiceMock';
import { AuthService } from './providers/auth';
import { AzureBlobStorageService } from './shared/services/azure-blob-storage.service';
import { AzureBlobStorageMockService } from './shared/mocks/azureBlobStorageMockService';
import { SharedModule } from './shared/shared.module';

@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
      SidebarComponent,
      PagesComponent,
      PageNotFoundComponent,
      SplashComponent,
      ValidateCasComponent,
   ],
   imports: [
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      AppRoutingModule,
      NgxPaginationModule,
      VisorCommonModule,
      NgChartsModule,
      AuthModule,
      BrowserAnimationsModule,
      NgxSpinnerModule,
      NgbModalModule,
      BacklogParametrizacionModule,
      SharedModule
   ],
   providers: [
      // { provide: TokenCasService, useClass: TokenCasServiceMock },
      // { provide: AuthService, useClass: AuthServiceMock },
      // { provide: AzureBlobStorageService, useClass: AzureBlobStorageMockService },
      {
         provide: BcpAuthContext,
         useFactory: AuthConfigurationFactory,
      },
      { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
   ],
   bootstrap: [
      AppComponent
   ],
   schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }