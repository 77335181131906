import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../providers/auth';
@Component({
  selector: 'visor-alert-close-session',
  templateUrl: './alert-close-session.component.html',
  styleUrls: ['./alert-close-session.component.scss']
})
export class AlertCloseSessionComponent implements OnInit {
  @Input() message: string;
  constructor(
    private activeModal: NgbActiveModal,
    private auth: AuthService,
  ) { }

  ngOnInit() {
  }
  closeSession() {
    this.auth.signout();
  }
  
  closeModal() {
    this.activeModal.close();
  }
}
