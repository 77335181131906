export const HEADER_ACCEPT = 'application/json';
export const GW_PATTERN = /.(\/api\/gw\/)./;
export const HEADER_APP_CODE = 'WH';
export const HEADER_CALLER_NAME = 'APWHDES';
export const TOKEN_Z = 'Z';
export const TIMEZONE = '-0400';
export const REQUEST_DATE = (function () {
  return {
    next: (): string => (new Date().toISOString().replace(TOKEN_Z, TIMEZONE))
  };
}());
export enum EHEADER {
  Accept = 'accept',
  XAuthToken = 'X-Auth-Token',
  UserEnrollment = 'user-enrollment',
  AppCode = 'app-code',
  CallerName = 'caller-name',
  RequestDate = 'request-date',
  RequestID = 'Request-ID',
  Bearer = 'Bearer ',
  Authorization = 'Authorization'
}
export const TIME_FORMATS = {
  DATE: 'dd/mm/yyyy',
  HOUR: 'hh:mm',
  DATE_TYPE_2: 'yyyy-mm-dd'
}
export const ALERTS_FILTER = {
  PENDING_QUALIFICATION: 'Alertas por calificar',
  PENDING: 'Alertas pendientes'
}
export const MODAL_TITLES = {
  MANAGEMENT: 'Gestión',
  DETAIL: 'Detalle'
}
export const USER_PROFILES = {
  ADMINISTRATOR: 'Administrator',
  SUPERVISOR: 'Supervisor',
  ANALYST: 'Analyst'
}
export const MAIN_PAGES = {
  ADMIN_MAIN_PAGE: 'consulta-alertas-agrupadas/:showPending',
  SUPERVISOR_MAIN_PAGE: 'consulta-alertas-agrupadas/:showPending',
  ANALYST_MAIN_PAGE: 'consulta-alertas-agrupadas/:showPending'
}