import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaderService } from '../../shared/services/http-header.service';
import { ENDPOINTS } from '../services/endpoint.enum';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  public endPointsReport = [
    `${ENDPOINTS.DASHBOARD_CATEGORIES}`,
    `${ENDPOINTS.DASHBOARD_MOTOR}`,
    `${ENDPOINTS.DASHBOARD_PENDING}`,
    `${ENDPOINTS.REPORT_ALERT}`,
    `${ENDPOINTS.REPORT_ALERT_TRAKING}`,
    `${ENDPOINTS.REPORT_DOWNLOAD_EXCEL}`,
    ENDPOINTS.FRAUDALERT_V1_ACTIONS,
    ENDPOINTS.FRAUDALERT_V1_ALERTS,
    `${ENDPOINTS.FRAUDALERT_V1_ALERTS}?stateAlert=pendiente`,
    ENDPOINTS.FRAUDALERT_V1_NOTIFICATIONS,
    ENDPOINTS.FRAUDALERT_V1_PREVIEW,
    ENDPOINTS.FRAUDALERT_V1_PREVIEW_UNBLOCKED,
    ENDPOINTS.FRAUDALERT_V1_TRACKING,
  ];
  constructor(
    private httpHeaders: HttpHeaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes('oauth2/token') && !req.url.includes('authserver')) {
      /*
      //console.log('endpointsRpo>', this.endPointsReport);
      console.log('httpresponse interseptor url>', req.url);
      if (this.endPointsReport.indexOf(req.url) == -1) {
        if(req.url=='https://int.apisdevbcp.com/channel/cbaf/report-management/v1/report'){
        console.log('Descarga de excel');
        const headers = this.httpHeaders.getInterceptorHeadersExcel();
        req = req.clone({ setHeaders: headers});
      }else{
        console.log('flag1');
        const headers = this.httpHeaders.getInterceptorHeaders();
        req = req.clone({ setHeaders: headers });
      }
      }else {
        console.log('flag2');
        const headers = this.httpHeaders.getInterceptorHeadersWithOutToken();
        console.log('Headers>', headers);
        req = req.clone({ setHeaders: headers });
      }
      */
     const headers = this.httpHeaders.getInterceptorHeadersWithOutToken();
     req = req.clone({ setHeaders: headers });
    }
    // console.log('httpresponse interseptor url>', req);
    return next.handle(req);
  }

}
