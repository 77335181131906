import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { ENDPOINTS } from "../../../../core/services/endpoint.enum";
import { AuthorizationFileRepository } from "../interfaces/authorization-file.repository";
import { AuthorizationFileRequest, AuthorizationFileResponse } from "../interfaces/authorization-file.interface";
@Injectable()
export class AuthorizationFileHttpRepository implements AuthorizationFileRepository{
    authorizeFile(obj: AuthorizationFileRequest): Observable<AuthorizationFileResponse> {
        return this.httpClient.post<any>(`${environment.API_URL_APLICATION_PARAMETER_MANAGEMENT}${ENDPOINTS.FILE_AUTHORIZATION}`, obj);
    }
    
    private readonly httpClient = inject(HttpClient);
}