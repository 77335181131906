import { Component, OnInit, inject } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AlertCloseSessionComponent } from '../common/popus/alert-close-session/alert-close-session.component';
import { AuthService } from '../providers/auth';
import { StoreService } from '../providers/store/store.service';
import { TokenCasService } from '../providers/token-cas/token-cas.service';
import { interval } from 'rxjs';
import { MAIN_PAGES, USER_PROFILES } from '../shared/const';
import { Router } from '@angular/router';
import { LOGIN_REPOSITORY } from '../shared/repositories/login/repository/login-http-repository.module';

@Component({
  selector: 'visor-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  private readonly loginRepository = inject(LOGIN_REPOSITORY);

  userName: string = "";
  userRole: string = "";
  timeLoginMin = 29;
  timeLoginSec = 59;
  intervalSec: any;
  intervalMin: any;
  castimertoken: any;
  tokenduration = 0;
  spinner: boolean = true;
  constructor(
    private auth: AuthService,
    private store: StoreService,
    private modal: NgbModal,
    private _modalConfig: NgbModalConfig,
    private _tokenCasSrv: TokenCasService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    if (this.store.getTokenAzure() && this.store.getTokenCas()) {
      this.setTimeOutLogin();
      this.getUserName();
      this.timerRefreshTokenCAS();     
    } else this.showSessionErrorModal();  
  }
  setTimeOutLogin() {
    this.intervalMin = setInterval(() => {
      --this.timeLoginMin;
      if (this.timeLoginMin == 0) {
        this.logout();
      }
    }, 60000);

    this.intervalSec = setInterval(() => {
      if (this.timeLoginSec == 0) this.timeLoginSec = 60;
      --this.timeLoginSec;
    }, 1000);
  }
  logout() {
    this.auth.signout();
  }
  getUserName() {
    this.loginRepository.searchCode()
      .subscribe((res: any) => {
        this.userName = this.auth.userName + '-' + res.code;
        this.store.saveUserCode(res.code);
        this.initNavigation();
      }, (e) => {
        console.log(e);
        this.showSessionErrorModal();
      });
  }
  
  timerRefreshTokenCAS(): void {
    this.castimertoken = interval(30000)
      .subscribe(() => {
        this._tokenCasSrv.getRefreshToken().subscribe((res: any) => {
          this.store.saveTokenCas(res.access_token)
          this.store.saveRefreshCAS(res.refresh_token)
          this.store.saveExpiresInCAS(res.expires_in)
          this.store.saveStartedCAS()
        }, (err) => {
          console.log(err);
          window.location.href = this._tokenCasSrv.getCodeOC();
        });
      });
  }
  showSessionErrorModal() {
    this.spinner = false;
    this.modal.open(AlertCloseSessionComponent, { backdropClass: 'cbaf-modal-backdrop' });
  }
  

  initNavigation() {
    const userInfo = this.auth.userData();
    const roles = userInfo.roles;
    let page: string[] = [];
    if (roles) {
      this.userRole = roles.slice(0, 1).toString();
      switch (this.userRole) {
        case USER_PROFILES.ADMINISTRATOR: page = [MAIN_PAGES.ADMIN_MAIN_PAGE]; break;
        case USER_PROFILES.SUPERVISOR: page = [MAIN_PAGES.SUPERVISOR_MAIN_PAGE]; break;
        case USER_PROFILES.ANALYST: page = [MAIN_PAGES.ANALYST_MAIN_PAGE]; break;
      }
      this._router.navigate(page);
      this.spinner = false;
    }
  }
}
