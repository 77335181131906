import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "../../providers/auth/auth.service";
@Component({
  selector: "visor-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() userName = "";
  @Input() timeLoginMin = 0;
  @Input() timeLoginSec = 0;
  @Input() spinner = true;

  constructor(
    private auth: AuthService,

  ) {
  }
  ngOnInit() { }

  logout() {
    this.auth.signout();
  }
}