import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametrizacionComponent } from './parametrizacion/parametrizacion.component';
import { RouterModule, Routes } from '@angular/router';
import { ActualizarMensajeComponent } from './actualizar-mensaje/actualizar-mensaje.component';
import { NgbModalModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BcpFormsModule } from '@bcp/ng-forms';
import { TiempoEsperaComponent } from './tiempo-espera/tiempo-espera.component';
import { EnvioMensajeSegmentoComponent } from './envio-mensaje-segmento/envio-mensaje-segmento.component';
import { MensajeFicoComponent } from './mensaje-fico/mensaje-fico.component';
import { ConfigurarPrioridadComponent } from './configurar-prioridad/configurar-prioridad.component';
import { DragulaModule } from 'ng2-dragula';
import { PopupConfirmacionComponent } from './popup-confirmacion/popup-confirmacion.component';
import { AgregarSegmentoComponent } from './envio-mensaje-segmento/agregar-segmento/agregar-segmento.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BacklogParametrizacionRepositoryModule } from './repository/backlog-parametrizacion-repository.module';
const routes: Routes = [
  {
    path: '',
    component: ParametrizacionComponent
  }
];
@NgModule({
  declarations: [
    ParametrizacionComponent,
    ActualizarMensajeComponent,
    TiempoEsperaComponent,
    MensajeFicoComponent,
    ConfigurarPrioridadComponent,
    PopupConfirmacionComponent,
    AgregarSegmentoComponent,
    EnvioMensajeSegmentoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModalModule,
    NgbNavModule,
    NgxSpinnerModule,
    FormsModule,
    BcpFormsModule, 
    ReactiveFormsModule,
    DragulaModule,
    BacklogParametrizacionRepositoryModule
  ],
  exports: [
    AgregarSegmentoComponent,
    EnvioMensajeSegmentoComponent
  ],
 /* entryComponents: [
    ActualizarMensajeComponent,
    PopupConfirmacionComponent,
    AgregarSegmentoComponent
  ]*/
})
export class BacklogParametrizacionModule { }
