import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng2SearchPipe } from './ng2-search.pipe';



@NgModule({
  declarations: [Ng2SearchPipe],
  imports: [
    CommonModule
  ],
  exports: [Ng2SearchPipe]
})
export class SharedPipesModule { }
