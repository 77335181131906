<div class="modal-content modal-container">
  <div class="modal-header">
    <h2 class="col">{{opts.title}}</h2>
  </div>
  <div class="modal-body">
    <ng-container [ngSwitch]="opts.modalType">
      <ng-container *ngSwitchCase="'error'">
        <label>{{ opts.subTitle }}</label>
        <ul *ngFor="let c of opts?.content.slice(0, 3)">
          <li>{{ c }}</li>
        </ul>
        <ul *ngIf="opts?.content.length > 3">
          <li *ngIf="!opts?.excelFile">[...]</li>
          <p *ngIf="opts?.excelFile">
            Se detectaron más errores. <u style="cursor: pointer;" class="font-weight-bold"
              (click)="download()">Descargar Excel de
              Errores</u> para verificar la lista de errores completa.</p>
        </ul>
        <span>{{ opts.disclamer }}</span>
      </ng-container>
      <ng-container *ngSwitchCase="'simple'">
        <p>{{opts.disclamer}}</p>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer text-center">
    <ng-container *ngIf="opts.confirmation">
      <button class="btn btn-primary" type="button" data-dismiss="modal"
        (click)="closeModal('SUCCESS')">Aceptar</button>
      <button class="btn btn-secondary" type="button" data-dismiss="modal"
        (click)="closeModal('CANCELED')">Cancelar</button>
    </ng-container>
    <ng-container *ngIf="!opts.confirmation">
      <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="closeModal()">Aceptar</button>
    </ng-container>
  </div>
  <ng-container style="display:none">
    <table id="error_excel" class="table table-hover" style="display:none" aria-label="Error excel">
      <thead>
        <tr class="text-center font-weight-bold full_space">
          <th colspan="2">Posición</th>
          <th colspan="5">Error(es)</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let element of errorList">
          <tr *ngFor="let e of element?.errors;let i =index">
            <td class="text-center" *ngIf="i == 0" colspan="2" [attr.rowspan]="element?.errors.length">
              {{ element.position}}
            </td>
            <td colspan="5"> {{ e }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>