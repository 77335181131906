<div class="bd-footer--background--light">
  <footer class="footer footer--light">
    <div class="container">
      <div class="row footer__content align-items-center no-gutters">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <ul class="footer__list-items">
            <li>&copy;2020 visor</li>
            <li>Todos los derechos reservados</li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 footer__content--right">
          <ul class="footer__list-items padding-small no-borders">
            <li><a href="#"><em class="icon bcp-envelope-o"></em>mailproyecto@bcp.com.pe</a></li>
            <li>tel. 311-9898</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</div>