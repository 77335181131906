import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'visor-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  ngOnInit() { }
}
