import { Component } from '@angular/core';

@Component({
  selector: 'visor-configurar-prioridad',
  templateUrl: './configurar-prioridad.component.html',
  styleUrls: ['./configurar-prioridad.component.scss']
})
export class ConfigurarPrioridadComponent {

}
