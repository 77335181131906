import { Component } from '@angular/core';

@Component({
  selector: 'visor-pagination-view',
  templateUrl: './pagination-view.component.html',
  styleUrls: ['./pagination-view.component.scss']
})
export class PaginationViewExampleComponent {

}
