import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    providers: [{
        multi: true,
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CheckboxComponent)
    }],
    selector: 'visor-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements ControlValueAccessor {

    checked: boolean;
    @Output() change: EventEmitter<boolean>;

    private changeFn: (obj: boolean) => void;
    private touchFn: () => void;

    constructor() {
        this.change = new EventEmitter<boolean>();
        this.changeFn = obj => {};
        this.touchFn = () => {};
    }

    get value(): boolean { return this.checked; }
    set value(obj: boolean) {
        if (obj !== this.checked) {
            this.checked = obj;
            this.changeFn(obj);
            this.change.emit(obj);
            this.touchFn();
        }
    }

    writeValue(obj: boolean): void { this.value = obj; }
    registerOnChange(fn: (obj: boolean) => void): void { this.changeFn = fn; }
    registerOnTouched(fn: () => void): void { this.touchFn = fn; }

}
