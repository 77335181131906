import { NgModule } from '@angular/core';
import { BcpAuthContext } from '@bcp/js-authentication';
import { AuthConfigurationFactory } from './auth.factory';

@NgModule({
  providers: [
    {
      provide: BcpAuthContext,
      useFactory: AuthConfigurationFactory,
    },
  ],
})
export class AuthModule { }