import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'visor-alert-one-button',
  templateUrl: './alert-one-button.component.html',
  styleUrls: ['./alert-one-button.component.scss']
})
export class AlertOneButtonComponent implements OnInit {
 @Input() message: string;
 public genericMessage ='Ocurrio un error inesperado.'
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    if(!this.message||this.message==''){
      this.message = this.genericMessage;
    }
  }
  closeModal() {
    this.activeModal.close();
  }

}
