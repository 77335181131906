
import { Injectable } from '@angular/core';
import { StoreService } from '../store/store.service';
import { BcpAuthContext } from '@bcp/js-authentication';
enum EStorage {
  Authorization = 'Authorization',
  Bearer = 'Bearer'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public codeAnalyst = { code: null };
  constructor(
    private storeService: StoreService,
    private auth:  BcpAuthContext,
  ) { }

  get userName(): string {
    return this.auth.getAccount() ? this.auth.getAccount()['name'] : '';
  }
  get userEmail(): string {
    return this.auth.getAccount() ? this.auth.getAccount()['username'] : '';
  }
  get roles(): string[] {
    return this.auth.getAccount()['idTokenClaims'] ? this.auth.getAccount()['idTokenClaims']['roles'] : [];
    // return ['Administrator'];
  }
  get upn() {
    return this.userEmail.split('@')[0];
  }
  validateTokenAzure(): boolean {
    const tokenAzure = this.storeService.getTokenAzure();
    const expires = Number(this.storeService.getExpiresInAzure() || 0);
    if (tokenAzure && expires > Math.floor(new Date().getTime() / 1000) && this.roles) {

      return true;
    }

    return false;
  }
  userData() {
    return this.auth.getAccount()['idTokenClaims'];
  }
  getCodeAnalystValue() {
    return this.codeAnalyst.code;
  }
  setCodeAnalystValue(value) {
    this.codeAnalyst.code = value;
  }
  deleteAuthorization() {
    sessionStorage.removeItem(EStorage.Authorization);
  }
  initRedirect(){
    return this.auth.initHandleRedirect();
  }
  signout() {
    // window.sessionStorage.clear();
    this.auth.logout();
  }
  startAuthentication() {
    this.auth.login();
  }
  isLoggedIn(){
    return this.auth.isAuthenticated();
  }
  getTokenAzure(){
    return this.auth.getToken();
  }
  isApplicationUser(): boolean {
    //Returns true if role is present in token (exist in CBAF)
    const cbafRole = this.auth.getAccount()['idTokenClaims']['roles'];
    return cbafRole ? true : false;
  }
}