export enum EPermissions {
// --------------------------- MENU --------------------------------
  ModuleAffiliation = 'ModuleAffiliation',
  ModuleMaintenance = 'ModuleMaintenance',
  ModuleGeneralParameters = 'ModuleGeneralParameters',
  ModuleException = 'ModuleException',
// ----------------------------- LOGIN ----------------------------------
  Login = 'Login',
// ----------------------------- Affiliation ----------------------------------

  // -> busqueda
  SearchCompaniesNotAffiliated = 'SearchCompaniesNotAffiliated',
  SearchCompaniesAffiliated = 'SearchCompaniesAffiliated',

  // -> afiliacion
  // CreateContract = 'CreateContract',
  ModifyEmailFn = 'ModifyEmailFn',
  AddServiceCompany = 'AddServiceCompany',
  ContinueAffiliation = 'ContinueAffiliation',

  // -> Dirección adicional
  // AffiliationShowCompanyAddresses = 'AffiliationShowCompanyAddresses',
  // AffiliationModifyCompanyAddress = 'AffiliationModifyCompanyAddress',

  // -> datos de contacto
  // AffiliationViewDataContact = 'AffiliationViewDataContact',
  // AffiliationModifyDataContact = 'AffiliationModifyDataContact',

  // -> Gerente de negocio
  // AffiliationViewBusinessManager = 'AffiliationViewBusinessManager',
  // AffiliationModifyBusinessManager = 'AffiliationModifyBusinessManager',

  // -> Lista Blanca
  AffiliationAddProvidersWhiteList = 'AffiliationAddProvidersWhiteList',
  AffiliationShowProviderWhiteList = 'AffiliationShowProviderWhiteList',
  AffiliationEditProviderWhiteList = 'AffiliationEditProviderWhiteList',
  AffiliationDeleteProviderWhiteList = 'AffiliationDeleteProviderWhiteList',

  // -> Gestión de usuarios
  AffiliationAddNewUser = 'AffiliationAddNewUser',

// ------------------------- Mantenimiento -----------------------------------
  // -> Perfiles del usuario
  // MaintenanceViewProfiles = 'MaintenanceViewProfiles',
  // MaintenanceModifyProfiles = 'MaintenanceModifyProfiles',
  // MaintenanceViewProfilesAnyPortfolio = 'MaintenanceViewProfilesAnyPortfolio',
  // MaintenanceViewProfilesYourPortfolio = 'MaintenanceViewProfilesYourPortfolio',
  MaintenanceModifyUserAnyPortfolio = 'MaintenanceModifyUserAnyPortfolio',
  // MaintenanceModifyUserYourPortfolio = 'MaintenanceModifyUserYourPortfolio',
  // MaintenanceSetSecurityUserAnyPortfolio = 'MaintenanceSetSecurityUserAnyPortfolio',
  // MaintenanceSetSecurityUserYourPortfolio = 'MaintenanceSetSecurityUserYourPortfolio',
  MaintenanceModifyProfilesAnyPortfolio = 'MaintenanceModifyProfilesAnyPortfolio',
  // MaintenanceModifyProfilesYourPortfolio = 'MaintenanceModifyProfilesYourPortfolio',
  MaintenanceModifyParametersServiceYourPortfolio = 'MaintenanceModifyParametersServiceYourPortfolio',
  MaintenanceAllParametersServiceAnyPortfolio = 'MaintenanceAllParametersServiceAnyPortfolio',
  MaintenanceViewWhiteList = 'MaintenanceViewWhiteList',
  // -> Datos del contacto
  MaintenanceViewDataContact = 'MaintenanceViewDataContact',
  MaintenanceModifyDataContact = 'MaintenanceModifyDataContact',

  // -> Desafiliar Servicio
  DisaffiliateService = 'DisaffiliateService',

  // -> Lista de Proveedores
  MaintenanceDisableWhiteList = 'MaintenanceDisableWhiteList',
  MaintenanceEditProviderWhiteList = 'MaintenanceEditProviderWhiteList',
  MaintenanceDeleteProviderWhiteList = 'MaintenanceDeleteProviderWhiteList',

// ----------------- Excepciones ------------------------------------------------------------------------------
  ExceptionCreateAnyPortfolio = 'ExceptionCreateAnyPortfolio',
  ExceptionViewAnyPortfolio = 'ExceptionViewAnyPortfolio',
  ExceptionFindAnyPortfolio = 'ExceptionFindAnyPortfolio',
  ExceptionApproveRegisterAnyPortfolio = 'ExceptionApproveRegisterAnyPortfolio',
  ExceptionModifyRegisterAnyPortfolio = 'ExceptionModifyRegisterAnyPortfolio',
  ExceptionRejectRegisterAnyPortfolio = 'ExceptionRejectRegisterAnyPortfolio',
  ExceptionModifyApproveAnyPortfolio = 'ExceptionModifyApproveAnyPortfolio',
  ExceptionRejectApproveAnyPortfolio = 'ExceptionRejectApproveAnyPortfolio',
  ExceptionModifyPartialAppliedAnyPortfolio = 'ExceptionModifyPartialAppliedAnyPortfolio',
  ExceptionRejectPartialAppliedAnyPortfolio = 'ExceptionRejectPartialAppliedAnyPortfolio',
  ExceptionViewReasonRejectAnyPortfolio = 'ExceptionViewReasonRejectAnyPortfolio',
  ExceptionModifyRegisterYourPortfolio = 'ExceptionModifyRegisterYourPortfolio',
  ExceptionCancelRegisterYourPortfolio = 'ExceptionCancelRegisterYourPortfolio',
  ExceptionModifyApproveYourPortfolio = 'ExceptionModifyApproveYourPortfolio',
  ExceptionCancelApproveYourPortfolio = 'ExceptionCancelApproveYourPortfolio',
  ExceptionModifyPartialAppliedYourPortfolio = 'ExceptionModifyPartialAppliedYourPortfolio',
  ExceptionCancelPartialAppliedYourPortfolio = 'ExceptionCancelPartialAppliedYourPortfolio',
  ExceptionCreateYourPortfolio = 'ExceptionCreateYourPortfolio'
}
