import { Component } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'visor-parametrizacion',
  templateUrl: './parametrizacion.component.html',
  styleUrls: ['./parametrizacion.component.scss']
})
export class ParametrizacionComponent {
  active = 'tab-a';
  constructor(
    private modal:NgbModal,
    private modalConfig: NgbModalConfig
  ) { }

}
