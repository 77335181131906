import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ModalComponent } from './modal.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DocumentHandlerService } from '../../services/DocumentHandler.service';

@NgModule({
  declarations: [
    ModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  /*entryComponents: [
    ModalComponent
  ],*/
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  providers:[
    DocumentHandlerService
  ]
})
export class ModalModule { }
