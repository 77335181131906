<br><br>
<div class="container">
  <div class="card w-75">
    <div class="card-body">
      <h5 class="card-title">Definir prioridad</h5>
      <p class="card-text">Selccione cúal tendrá prioridad, lista negras, listas blancas ó segmentos.</p>
    </div>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
    <label class="form-check-label" for="exampleRadios1">
      Segmento
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
    <label class="form-check-label" for="exampleRadios2">
      Listas blancas.
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
    <label class="form-check-label" for="exampleRadios2">
      Listas negras.
    </label>
  </div>
  <br>
  <br>
  <div>
    <button class="btn btn-primary">Aceptar</button>
  </div>
</div>