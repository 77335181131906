import { Component, Input } from '@angular/core';
import { USER_PROFILES } from '../../shared/const';

@Component({
  selector: 'visor-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  /*
ADMINISTRADOR -> CBAF_ADMINISTRADOR_PROD
SUPERVISOR -> CBAF_SUPERVISOR_PROD
ANALISTA -> CBAF_ANALISTA_PROD
REGULADOR - > CBAF_REGULADOR_PROD
  */
  @Input() userRole: string = '';
  @Input() userName = "";
  roles = [];
  admin = false;
  supervisor = false;
  analista = false;
  regulador = false;

  ngOnChanges() {
    switch (this.userRole) {
      case USER_PROFILES.ADMINISTRATOR: this.admin = true; break;
      case USER_PROFILES.SUPERVISOR: this.supervisor = true; break;
      case USER_PROFILES.ANALYST: this.analista = true; break;
      case USER_PROFILES.REGULATOR: this.regulador = true; break;
    }
  }
}