<div class="modal-content">
  <div class="modal-header">
    <h4 class="col">Agregar Segmento</h4>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <form [formGroup]="formSegment" (ngSubmit)="addSegment()">
        <div class="form-group row no-gutters">
          <br>
          <div class="col-sm-12 select-container">
            <label>Descripción del segmento</label>
            <div *ngIf="getFormControllerName.hasError('minlength')" class="alert alert-danger">
              La descripción no puede tener menos de 5 caracteres.
            </div>
            <div *ngIf="getFormControllerName.hasError('maxlength')" class="alert alert-danger">
              La descripción no puede tener mas de 50 caracteres.
            </div>
            <div *ngIf="getFormControllerName.hasError('onlyLettersAndNumbers')" class="alert alert-danger">
              La descripción no puede tener caracteres especiales
            </div>
            <input class="form-control" formControlName="formSegmentName" type="text" required>
          </div>
          <div class="col-sm-12 select-container">
            <label>ID Segmento</label>
            <div *ngIf="getFormControllerIdSegment.hasError('minlength')" class="alert alert-danger">
              El ID no puede ser menor a 5.
            </div>
            <div *ngIf="getFormControllerIdSegment.hasError('maxlength')" class="alert alert-danger">
              El ID no puede ser mayor a 30.
            </div>
            <input class="form-control" formControlName="formIdSegmento" type="text" required>
          </div>
          <div class="col-sm-12 select-container">
            <button type="submit" class="btn btn-primary" [disabled]="formSegment.invalid">Crear</button>
          </div>
        </div>
      </form>
      <button class="btn btn-primary" (click)=initForm()>
        Limpiar
      </button>
      <button class="btn btn-primary" (click)=closeModal()>Cancelar</button>
    </div>
  </div>
</div>