import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'visor-empty-msg',
  templateUrl: './empty-msg.component.html',
  styleUrls: ['./empty-msg.component.scss']
})
export class EmptyMsgComponent implements OnInit {
  @Input() feedbackMsg: string = '';
  @Input() textButton: string = '';
  @Input() showButton: boolean = false;
  @Output() emitter = new EventEmitter();

  ngOnInit() {
  }
  emit() {
    this.emitter.emit('emptyClicked');
  }

}
