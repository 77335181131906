import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SplashComponent } from './splash/splash.component';
import { authGuard} from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: 'splash',
        pathMatch: 'full',
      },
      {
        path: 'splash',
        component: SplashComponent,
      },
      {
        path: 'reportes',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportModule),
      },
      {
        path: 'crear-regla',
        loadChildren: () => import('./pages/crear-regla/crear-regla.module').then(m => m.CrearReglaModule),
      },
      {
        path: 'mantenimiento-parametros',
        loadChildren: () => import('./pages/mantenimiento-parametros-generales/mantenimiento-parametros-generales.module').then(m => m.MantenimientoParametrosGeneralesModule),
      },
      {
        path: 'parametrizacion',
        loadChildren: () => import('./pages/backlog-parametrizacion/backlog-parametrizacion.module').then(m => m.BacklogParametrizacionModule),//loadChildren:() => import('./pages/parametrizacion/parametrizacion.module').then(m => m.ParametrizacionModule),//loadChildren: './pages/parametrizacion/parametrizacion.module#ParametrizacionModule',
      },
      {
        path: 'carga-usuarios',
        loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule),
      },
      {
        path: 'consulta-casos',
        loadChildren: () => import('./pages/case-consultation/case-consultation.module').then(m => m.CaseConsultationModule),
      }
    ]
  },
  {
    path: 'validate-cas',
    loadChildren: () => import('./validate-cas/validate-cas.module').then(m => m.ValidateCasModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }