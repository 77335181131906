import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDropdown } from './interfaces/IDropdown';

@Component({
  selector: 'visor-dropdown',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss']
})
export class DropdownComponent {

  @Input() objDropdown: IDropdown;
  @Output() changeDropdown = new EventEmitter();
  isOpen = false;

  onItem(item: any) {
    this.objDropdown.itemSelected = item;
    this.isOpen = false;
    this.changeDropdown.emit(this.objDropdown.itemSelected);
  }

  onClickDropdown() {
    this.isOpen = !this.isOpen;
  }

  onBlurDropdown() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }
}
