import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'visor-popup-confirmacion',
  templateUrl: './popup-confirmacion.component.html',
  styleUrls: ['./popup-confirmacion.component.scss']
})
export class PopupConfirmacionComponent implements OnInit {
  @Input() mensaje: string;
  @Input() accion: any;
  @Input() parametros: any;
  constructor(
    private activeModal: NgbActiveModal

  ) { }

  ngOnInit() {
  }

  cancelar() {
    this.activeModal.close();
  }
}
