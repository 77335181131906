import { Component, OnInit } from '@angular/core';

@Component({
  selector: "visor-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit{
  
  ngOnInit() {
  } 
  
}