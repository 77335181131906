import { HttpClientModule } from "@angular/common/http";
import { InjectionToken, NgModule } from "@angular/core";
import { AuthorizationFileRepository } from "../interfaces/authorization-file.repository";
import { AuthorizationFileHttpRepository } from "./authorization-file-http.repository";

export const AUTHORIZATION_FILE_REPOSITORY = new InjectionToken<AuthorizationFileRepository>('AuthorizationFileRepository');

@NgModule({
  providers: [
    {
      provide: AUTHORIZATION_FILE_REPOSITORY,
      useClass: AuthorizationFileHttpRepository,
    },
  ],
  imports: [
    HttpClientModule,
  ],
})
export class AuthorizationFileRepositoryModule { }