<br>
<div>
  <ul>
    <li *ngFor="let item of listaTipoMensaje; index as i">
      <span (click)="selectTipoMensaje(i)" class="btn btn-outline-primary">{{item.label}}</span>
    </li>
  </ul>
  <form [formGroup]="formFico">
    <div class="container" *ngIf="selectType">
      <div class="row">
        <bcp-textarea
        style="width: 100%;"
          label="Edita tu mensaje aquí"
          formControlName="mensaje" 
          bcpCtrlForm
          [rows]="5"
          [autogrow]="true"
          > 
        </bcp-textarea>
      </div>
        <div  class="row">
          <p>Las Variables se usan con un $ al principio del texto, las variables que se estén usando se pintarán de verde, las que no estarán de color rojo.</p>
          <ul [dragula]="'bag-items'" [dragulaModel]='campos' style="width: 100%;">
            <li *ngFor="let variable of campos; index as i">
              <span [ngClass]="variable.isUse? 'badge badge-success':'badge badge-danger'">{{variable.variable}}</span>
            </li>
          </ul>
        </div>
      
      <div>
          <button class="btn btn-primary" (click)="upsateEmail(mensajeEditado) ">Guardar</button>
      </div>
    </div>
  </form>
</div>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>