<nav class="row col-md-2 d-none d-md-block sidebar">
	<div class="sidebar-sticky">
		<ul class="nav flex-column">
			<li class="nav-item" routerLinkActive="active" *ngIf="supervisor || admin || analista"><a class="nav-link"
					routerLink="/consulta-alertas-agrupadas/true" routerLinkActive="active"><span
						data-feather="file"></span>
					Consulta de Alertas</a></li>
			<li class="nav-item" routerLinkActive="active" *ngIf="admin"><a class="nav-link bd-toc-link"
					routerLink="/carga-usuarios" routerLinkActive="active"><span data-feather="home"></span>
					Usuarios<span class="sr-only">(current)</span></a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="admin"><a class="nav-link"
					routerLink="/mantenimiento-parametros" routerLinkActive="active"><span data-feather="file"></span>
					Parámetros Generales</a></li>
			<li class="nav-item" routerLinkActive="active" *ngIf="supervisor || admin"><a class="nav-link"
					routerLink="/reportes" routerLinkActive="active"><span data-feather="report"></span> Reportes</a>
			</li>
			<li class="nav-item" routerLinkActive="active"><a class="nav-link" routerLink="/parametrizacion"
					routerLinkActive="active" *ngIf="admin"><span></span>Parametrización</a></li>
		</ul>
	</div>
</nav>