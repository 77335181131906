<div class="container">
  <label for="">Seleccione el tipo de mensaje a editar</label>
  <select name="" id=""></select>
</div>
<div class="container">
  <h3>Editar campos</h3>
  <label for="">Primer campo</label>
  <input type="text">
  <label for="">Segundo campo</label>
  <input type="text" name="" id="">
  <label for="">Tercer campo</label>
  <input type="text" name="" id="">
</div>